import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DocumentType } from '../../models/DocumentType';
import { AuthenticatedDownloadLink } from '../document/AuthenticatedDownloadLink';
import { EmptyResults } from '../general/EmptyResults';

export function DocumentationTab({
	candidatureChange,
	candidatureChangeDocuments,
}) {
	const processDocumentTypes = [
		DocumentType.VALIDATION_DOCUMENT,
		DocumentType.VALIDATION_DOCUMENT_PRIVATE,
		DocumentType.FINAL_DECISION,
		DocumentType.FINAL_DECISION_PRIVATE,
		DocumentType.TERM_ACCEPT_DRAFT,
		DocumentType.TERM_ACCEPT,
		DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT,
		DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT_SIGNED
	];

	const filteredDocuments = getDocumentsInOrder();


	function getDocumentsInOrder() {
		let finalDocs = [];
		processDocumentTypes.forEach((dType) => {
			let documentAfterFilter = candidatureChangeDocuments.filter(
				(d) => d.documentType === dType
			);
			if (documentAfterFilter.length > 0) {
				finalDocs.push(documentAfterFilter[0]);
			}
		});
		console.log(candidatureChangeDocuments)
		return finalDocs;
	}

	return (
		<div>
			{filteredDocuments.length === 0 ? (
				<EmptyResults />
			) : (
				filteredDocuments.map((d, index) => (
					<Row key={index} className='mt-4'>
						<Col>
							<label className='mb-0'>
								<FormattedMessage id={`documentType.${d.documentType}`} />
							</label>
							<div>
								<AuthenticatedDownloadLink
									url={d.documentPath}
									filename={d.name}
								>
									<small>{d.name}</small>
								</AuthenticatedDownloadLink>
							</div>
						</Col>
					</Row>
				))
			)}
		</div>
	);
}
